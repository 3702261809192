import { css } from "@emotion/react";

export const loginBtn = css({
  background: "#fff",
  color: "#000000",
  maxWidth: "250px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "50px",
  cursor: "pointer",
  border: "none",
  borderRadius: "4px",
  fontWeight: "500",
  fontSize: "1.4rem",
  ":hover": {
    background: "#000000",
    color: "#fff",
  },
});

export const loginWrapper = css({
  margin: "140px 20px 20px 20px",
  backgroundColor: "#1F8290",
  padding: "20px 10px",
  borderRadius: "14px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  maxWidth: "450px",
  width: "100%",
});

export const loginText = css({
  color: "#fff",
  fontSize: "1.5rem",
  textDecoration: "none",
});
