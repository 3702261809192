/* eslint-disable react/no-unknown-property */
import Logo from "../../ui/static/Logo.svg";
import { logoTitle } from "../Header/Header.styles";
import { loginBtn, loginText, loginWrapper } from "./Login.style";

export const LoginPage = () => {
  return (
    <>
      <div className="login-background w-100 h-100 d-flex justify-content-center align-items-start">
        <div css={loginWrapper}>
          <div className="d-flex-column justify-content-center align-items-center mb-2">
            <img width="80" height="auto" src={Logo} alt="Logo" />
            <h1 css={logoTitle} className="ml-2">
              Immutable
            </h1>
          </div>
          <p css={loginText} className="mt-3 mb-2">
            The new enterprise ordering experience <br /> from Trust Panda is
            live.
          </p>
          <a href="https://app.trustpanda.com" css={loginBtn} className="px-2">
            Log in to Trust Panda Portal
          </a>
        </div>
      </div>
    </>
  );
};
