/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from "react";
import { LoginPage } from "./components/LoginPage/Login";
import Header from "./components/Header/Header";
import { deleteAllCookies, isCookiePresent } from "./ui/helpers/index";
import config from "react-global-configuration";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import configuration from "./config";
import { Amplify, Hub } from "aws-amplify";
import "react-toastify/dist/ReactToastify.css";

Amplify.configure(configuration.Auth);
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
config.set(configuration);

export const App = () => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(
    isCookiePresent(
      `CognitoIdentityServiceProvider.${configuration.Auth.userPoolWebClientId}.LastAuthUser`
    )
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  Hub.listen("auth", (data: any) => {
    // alert(data.payload.event)
    switch (data.payload.event) {
      case "signIn":
        setIsAuthorized(
          isCookiePresent(
            `CognitoIdentityServiceProvider.${configuration.Auth.userPoolWebClientId}.LastAuthUser`
          )
        );
        break;
      case "oAuthSignOut":
        deleteAllCookies();
        setIsAuthorized(
          isCookiePresent(
            `CognitoIdentityServiceProvider.${configuration.Auth.userPoolWebClientId}.LastAuthUser`
          )
        );
        break;
      default:
        break;
    }
  });

  return (
    <>
      <Header isAuthorized={isAuthorized} />
      <LoginPage />
    </>
  );
};
